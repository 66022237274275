<template>
  <div class="pos-checkout pos-order">
    <v-layout class="margin-unset w-100">
      <v-flex md4 class="p-0 pos-order-left btx-border-right">
        <v-layout class="cyan">
          <v-flex class="px-4"
            ><v-btn v-on:click="goBack()" icon large color="white" tile depressed
              ><v-icon large>mdi-arrow-left-bold-circle-outline</v-icon></v-btn
            ></v-flex
          >
          <v-flex v-if="bill_split" class="pos-bill-split d-flex justify-end">
            <v-layout>
              <v-flex v-if="bill_count[1]">
                <v-btn
                  depressed
                  tile
                  :disabled="formLoading || tableLoading"
                  color="cyan h-100 white--text w-100"
                  v-on:click="bill_tab = 'table_tab_1'"
                  :class="{
                    'darken-3': bill_tab == 'table_tab_1',
                    'darken-1': bill_tab != 'table_tab_1',
                  }"
                >
                  <v-badge color="teal darken-4" :content="bill_count[1]">
                    <span class="normal-text-2">Bill 1</span>
                  </v-badge>
                </v-btn>
              </v-flex>
              <v-flex v-if="bill_count[2]">
                <v-btn
                  depressed
                  tile
                  :disabled="formLoading || tableLoading"
                  color="cyan h-100 white--text w-100"
                  v-on:click="bill_tab = 'table_tab_2'"
                  :class="{
                    'darken-3': bill_tab == 'table_tab_2',
                    'darken-1': bill_tab != 'table_tab_2',
                  }"
                >
                  <v-badge color="teal darken-4" :content="bill_count[2]">
                    <span class="normal-text-2">Bill 2</span>
                  </v-badge>
                </v-btn>
              </v-flex>
              <v-flex v-if="bill_count[1]">
                <v-btn
                  depressed
                  tile
                  :disabled="formLoading || tableLoading"
                  color="cyan h-100 white--text w-100"
                  v-on:click="bill_tab = 'table_tab_3'"
                  :class="{
                    'darken-3': bill_tab == 'table_tab_3',
                    'darken-1': bill_tab != 'table_tab_3',
                  }"
                >
                  <v-badge color="teal darken-4" :content="bill_count[3]">
                    <span class="normal-text-2">Bill 3</span>
                  </v-badge>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex v-else class="pos-bill-split d-flex justify-end">
            <p class="m-0 normal-text-2 px-8 white--text p-2">{{ table.name }}</p>
          </v-flex>
        </v-layout>
        <div class="pos-order-items" v-if="table_items[bill_tab].length">
          <v-chip
            color="cyan darken-3"
            text-color="white"
            :disabled="formLoading || tableLoading"
            v-on:click="selectAllItem()"
            class="btx-tile-chip pos-item-select-all normal-text-2 h-22px w-100 justify-center"
          >
            <template v-if="all_selected">DESELECT ALL</template>
            <template v-else>SELECT ALL</template>
          </v-chip>
          <table class="w-100">
            <template v-for="(row, index) in table_items[bill_tab]">
              <tr
                :key="`table-item-${index}`"
                class="table-item"
                :class="getAlternativeClass(index)"
                :id="`table-item-${index}`"
              >
                <td class="py-2 pl-2" width="60%">
                  <v-checkbox
                    :disabled="formLoading || tableLoading"
                    v-on:change="updateSelected()"
                    v-model="row.status"
                    hide-details
                    color="cyan"
                    class="m-0"
                  >
                    <template v-slot:label>
                      <div class="d-block">
                        <v-chip
                          label
                          x-small
                          color="cyan darken-3"
                          text-color="white"
                          class="h-20px justify-center"
                          >{{ row.barcode }}</v-chip
                        >
                        <p class="normal-text-2 mt-1 m-0 ellipsis-4-line">{{ row.name }}</p>
                      </div>
                    </template>
                  </v-checkbox>
                </td>
                <td align="center" width="20%" class="py-4 px-2">
                  <v-layout class="align-center">
                    <v-flex>
                      <v-btn
                        v-on:click="updateQty(index, 'decrease')"
                        icon
                        :disabled="formLoading || tableLoading"
                        color="red lighten-1"
                        class="bg-grey-lighten-4 btx-border"
                        ><v-icon large>mdi-minus</v-icon></v-btn
                      >
                    </v-flex>
                    <v-flex class="mx-2 normal-text-3">x{{ row.quantity }}</v-flex>
                    <v-flex>
                      <v-btn
                        v-on:click="updateQty(index, 'increase')"
                        icon
                        :disabled="formLoading || tableLoading"
                        color="green lighten-1"
                        class="bg-grey-lighten-4 btx-border"
                        ><v-icon large>mdi-plus</v-icon></v-btn
                      >
                    </v-flex>
                  </v-layout>
                  <v-chip
                    :disabled="formLoading || tableLoading"
                    color="cyan"
                    text-color="white"
                    v-if="row.discount_type != 'foc'"
                    class="btx-tile-chip mt-4 h-22px normal-text-2 w-100 justify-center"
                  >
                    {{ getItemTotal(index) }}
                  </v-chip>
                </td>
              </tr>
              <template v-if="row.add_ons && row.add_ons.length">
                <tr
                  class="table-item-addon"
                  :class="getAlternativeClass(index)"
                  v-for="(arow, aindex) in row.add_ons"
                  :key="`table-item-addon-${index}-${aindex}`"
                >
                  <td colspan="2" :key="aindex">
                    <v-layout class="mx-3 py-2 align-center">
                      <v-flex class="max-width-50px my-auto text-center">
                        <v-btn
                          icon
                          :disabled="formLoading || tableLoading"
                          color="red lighten-1"
                          class="addon-action"
                          v-on:click="removeAddOn(index, aindex)"
                        >
                          <v-icon>mdi-delete-forever</v-icon>
                        </v-btn>
                      </v-flex>
                      <v-flex md8 class="normal-text-1 ellipsis-4-line">
                        {{ arow.name }}
                        <template v-if="arow.quantity > 1">x{{ arow.quantity }} </template>:
                      </v-flex>
                      <v-flex
                        class="text-center normal-text-1-bold normal-text-1"
                        v-if="row.discount_type != 'foc'"
                        >{{ formatCurrency(arow.total) }}</v-flex
                      >
                    </v-layout>
                  </td>
                </tr>
              </template>
              <template v-if="row.discount_type">
                <tr
                  class="table-item-addon"
                  :class="getAlternativeClass(index)"
                  :id="`table-item-discount-${index}`"
                  :key="`table-item-discount-${index}`"
                >
                  <td colspan="2">
                    <v-layout class="mx-3 py-2 align-center">
                      <v-flex class="max-width-50px my-auto text-center">
                        <v-btn
                          icon
                          :disabled="formLoading || tableLoading"
                          color="red lighten-1"
                          class="addon-action"
                          v-on:click="removeDiscount(index)"
                        >
                          <v-icon>mdi-delete-forever</v-icon>
                        </v-btn>
                      </v-flex>
                      <v-flex md8 class="normal-text-1 ellipsis-4-line">
                        <template v-if="row.discount_type == 'percentage'"
                          >Discount [{{ row.discount_value }}%] :
                        </template>
                        <template v-if="row.discount_type == 'amount'"
                          >Discount [{{ formatCurrency(row.discount_value) }}] :
                        </template>
                        <template v-if="row.discount_type == 'foc'"
                          >FREE as {{ getGiftName(row.gift) }}</template
                        >
                      </v-flex>
                      <v-flex
                        class="text-center normal-text-1-bold normal-text-1"
                        v-if="row.discount_type != 'foc'"
                        >{{ formatCurrency(row.discount_amount) }}</v-flex
                      >
                    </v-layout>
                  </td>
                </tr>
              </template>
              <template v-if="row.remark">
                <tr
                  class="table-item-addon"
                  :class="getAlternativeClass(index)"
                  :key="`table-item-remark-${index}`"
                  :id="`table-item-remark-${index}`"
                >
                  <td colspan="2">
                    <v-layout class="mx-3 py-2 align-center">
                      <v-flex class="max-width-50px my-auto text-center">
                        <v-btn
                          icon
                          :disabled="formLoading || tableLoading"
                          color="red lighten-1"
                          class="addon-action"
                          v-on:click="remarkClear(index)"
                        >
                          <v-icon>mdi-delete-forever</v-icon>
                        </v-btn>
                      </v-flex>
                      <v-flex class="normal-text-1"
                        >Remarks:
                        <span class="font-weight-bolder ml-2">{{ row.remark }}</span></v-flex
                      >
                    </v-layout>
                  </td>
                </tr>
              </template>
            </template>
            <tr
              v-for="(trow, tindex) in table_add_ons"
              class="table-item"
              :key="`table-addon-${tindex}`"
              :id="`table-addon-${tindex}`"
            >
              <td class="py-2 pl-2" width="60%">
                <v-layout>
                  <v-flex class="max-width-50px my-auto text-center">
                    <v-btn
                      icon
                      :disabled="formLoading || tableLoading"
                      color="red lighten-1"
                      class="addon-action"
                      v-on:click="removeTableAddOn(tindex)"
                    >
                      <v-icon>mdi-delete-forever</v-icon>
                    </v-btn>
                  </v-flex>
                  <v-flex class="normal-text-1 my-auto">
                    <p class="normal-text-2 mt-1 m-0 ellipsis-4-line">
                      {{ trow.name }} <span v-if="trow.quantity > 1">x{{ trow.quantity }}</span
                      >:
                    </p>
                  </v-flex>
                </v-layout>
              </td>
              <td align="center" width="20%" class="py-4 px-2">
                <v-chip
                  color="cyan"
                  :disabled="formLoading || tableLoading"
                  text-color="white"
                  class="btx-tile-chip h-22px normal-text-2 w-100 justify-center"
                >
                  {{ formatCurrency(trow.total) }}
                </v-chip>
              </td>
            </tr>
            <tr
              v-for="(terow, tindex) in table_extra_fees"
              class="table-item"
              :key="`table-extra-fee-${tindex}`"
              :id="`table-extra-fee-${tindex}`"
            >
              <td class="py-2 pl-2" width="60%">
                <v-layout>
                  <v-flex class="max-width-50px my-auto text-center">
                    <v-btn
                      icon
                      :disabled="formLoading || tableLoading"
                      color="red lighten-1"
                      class="addon-action"
                      v-on:click="removeTableExtra(tindex)"
                    >
                      <v-icon>mdi-delete-forever</v-icon>
                    </v-btn>
                  </v-flex>
                  <v-flex class="normal-text-1 my-auto">
                    <p class="normal-text-2 m-0 ellipsis-4-line">{{ terow.name }} :</p>
                    <em>{{ terow.description }}</em>
                  </v-flex>
                </v-layout>
              </td>
              <td align="center" width="20%" class="py-4 px-2">
                <v-chip
                  color="cyan"
                  :disabled="formLoading || tableLoading"
                  text-color="white"
                  class="btx-tile-chip h-22px normal-text-2 w-100 justify-center"
                >
                  {{ formatCurrency(terow.amount) }}
                </v-chip>
              </td>
            </tr>
            <tr v-if="table.discount_type == 'foc'" class="table-item">
              <td colspan="2" class="py-2 pl-2" width="100%">
                <v-layout>
                  <v-flex class="max-width-50px my-auto text-center">
                    <v-btn
                      icon
                      :disabled="formLoading || tableLoading"
                      color="red lighten-1"
                      class="addon-action"
                      v-on:click="removeTableGift()"
                    >
                      <v-icon>mdi-delete-forever</v-icon>
                    </v-btn>
                  </v-flex>
                  <v-flex class="my-auto">
                    <p class="normal-text-2 mt-1 m-0 ellipsis-4-line">
                      FREE as {{ getGiftName(table.gift) }}
                    </p>
                  </v-flex>
                </v-layout>
              </td>
            </tr>
            <tr v-if="table.discount_amount" class="table-item" id="table-discount">
              <td class="py-2 pl-2" width="80%">
                <v-layout>
                  <v-flex class="max-width-50px my-auto text-center">
                    <v-btn
                      icon
                      :disabled="formLoading || tableLoading"
                      color="red lighten-1"
                      class="addon-action"
                      v-on:click="removeTableDiscount()"
                    >
                      <v-icon>mdi-delete-forever</v-icon>
                    </v-btn>
                  </v-flex>
                  <v-flex md8 class="normal-text-2 ellipsis-4-line my-auto">
                    <template v-if="table.discount_type == 'percentage'"
                      >Discount [{{ table.discount_value }}%] :
                    </template>
                    <template v-if="table.discount_type == 'amount'"
                      >Discount [{{ formatCurrency(table.discount_value) }}] :
                    </template>
                    <template v-if="table.discount_type == 'foc'"
                      >FREE as {{ getGiftName(table.gift) }}</template
                    >
                  </v-flex>
                </v-layout>
              </td>
              <td align="center" width="20%" class="py-4 px-2">
                <v-chip
                  color="cyan"
                  :disabled="formLoading || tableLoading"
                  text-color="white"
                  class="btx-tile-chip h-22px normal-text-2 w-100 justify-center"
                >
                  {{ formatCurrency(table.discount_amount) }}
                </v-chip>
              </td>
            </tr>
            <tr v-if="table.coupon_id" class="table-item" id="table-coupon">
              <td class="py-2 pl-2" width="80%">
                <v-layout>
                  <v-flex class="max-width-50px my-auto text-center">
                    <v-btn
                      icon
                      :disabled="formLoading || tableLoading"
                      color="red lighten-1"
                      class="addon-action"
                      v-on:click="removeCoupon()"
                    >
                      <v-icon>mdi-delete-forever</v-icon>
                    </v-btn>
                  </v-flex>
                  <v-flex md8 class="normal-text-2 ellipsis-4-line my-auto">
                    <p class="m-0">Coupon Discount :</p>
                    <em class="normal-text-1">{{ table.coupon }}</em>
                  </v-flex>
                </v-layout>
              </td>
              <td align="center" width="20%" class="py-4 px-2">
                <v-chip
                  color="cyan"
                  :disabled="formLoading || tableLoading"
                  text-color="white"
                  class="btx-tile-chip h-22px normal-text-2 w-100 justify-center"
                >
                  {{ formatCurrency(table.coupon_amount) }}
                </v-chip>
              </td>
            </tr>
            <tr v-if="table_order_remark" class="table-item table-order-remark" id="table-remark">
              <td colspan="2" class="py-2 pl-2" width="100%">
                <v-layout>
                  <v-flex class="max-width-50px my-auto text-center">
                    <v-btn
                      icon
                      :disabled="formLoading || tableLoading"
                      color="red lighten-1"
                      class="addon-action"
                      v-on:click="remarkOrderClear()"
                    >
                      <v-icon>mdi-delete-forever</v-icon>
                    </v-btn>
                  </v-flex>
                  <v-flex md9 class="normal-text-2 my-auto"
                    >Remarks:
                    <span class="font-weight-bolder ml-2">{{ table_order_remark }}</span></v-flex
                  >
                </v-layout>
              </td>
            </tr>
          </table>
        </div>
        <div v-else class="w-100 text-color text-center mt-20 pos-no-item-selected">
          <template v-if="tableLoading">
            <p class="m-0 normal-text-3 text--secondary text-center">Loading...</p>
            <v-progress-linear
              color="cyan"
              class="w-80 mx-auto mt-10"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </template>
          <template v-else>
            <p class="m-0 normal-text-3 text--secondary text-center">No Item Selected</p>
            <p class="m-0 normal-text-1 text--secondary text-center">
              Please select item from menu
            </p>
          </template>
          <v-img
            contain
            width="150"
            :src="$assetURL('media/logos/logo-tt.png')"
            class="brand-logo mx-auto mt-10"
            alt="brand-logo"
          ></v-img>
        </div>
        <div class="pos-order-total p-2 grey lighten-3">
          <v-layout class="mb-3 mt-2">
            <v-flex>
              <p class="m-0 normal-text-3">Total ({{ table_items[bill_tab].length }})</p>
            </v-flex>
            <v-flex class="text-right">
              <p class="m-0 normal-text-3">{{ totalAmount() }}</p>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex md6 class="mr-2">
              <v-btn
                tile
                :disabled="formLoading || !table_items[bill_tab].length"
                :loading="formLoading"
                depressed
                class="w-100"
                color="green lighten-1 white--text"
                ><v-icon>mdi-chevron-left</v-icon> Send to Kitchen</v-btn
              >
            </v-flex>
            <v-flex md6 class="ml-2 text-right">
              <v-btn
                tile
                :disabled="formLoading || !table_items[bill_tab].length"
                :loading="formLoading"
                v-on:click="pushToCheckout()"
                depressed
                class="w-100"
                color="cyan white--text"
                >{{ totalAmount() }} <v-icon>mdi-chevron-right</v-icon></v-btn
              >
            </v-flex>
          </v-layout>
        </div>
      </v-flex>
      <v-flex md4 class="px-4 pt-4 grey lighten-3 text-center">
        <div class="bg-white btx-pos-middle-actions btx-border-radius-4px px-4 pt-4">
          <template v-if="isNotEmpty(customer)">
            <v-layout class="position-relative btx-border btx-border-radius-4px py-2">
              <v-flex md4 class="text-center">
                <v-avatar size="70">
                  <v-img
                    contain
                    :src="$assetURL(customer.profile)"
                    :lazy-src="$assetURL('media/users/default.jpg')"
                  ></v-img>
                </v-avatar>
              </v-flex>
              <v-flex md8 class="ml-3 text-left my-auto">
                <p
                  class="my-1 small-text-2 text-truncate text-capitalize"
                  v-if="customer.full_name"
                >
                  {{ customer.full_name }}
                </p>
                <p class="my-1 small-text-2 text-truncate" v-if="customer.phone_number">
                  {{ customer.phone_number }}
                </p>
                <p class="my-1 small-text-2 text-truncate text-lowercase" v-if="customer.email">
                  {{ customer.email }}
                </p>
                <v-btn
                  v-on:click="removeCustomer()"
                  class="btx-customer-remove-btn"
                  icon
                  color="red lighten-1"
                  ><v-icon>mdi-delete-forever</v-icon></v-btn
                >
              </v-flex>
            </v-layout>
          </template>
          <template v-else>
            <v-layout class="mt-2">
              <v-flex class="mr-2">
                <v-btn
                  tile
                  depressed
                  v-on:click="walkInCustomer = true"
                  class="w-100 btx-border"
                  :class="{ 'cyan white--text': !walkInCustomer, 'cyan--text': walkInCustomer }"
                  >Walk In</v-btn
                >
              </v-flex>
              <v-flex class="ml-2">
                <v-btn
                  tile
                  depressed
                  v-on:click="showCustomerDialog()"
                  class="w-100"
                  color="cyan white--text"
                  >Customer</v-btn
                >
              </v-flex>
            </v-layout>
            <p class="m-0 small-text-1 mt-3">Points are available after login</p>
          </template>
          <template v-if="discountBlock">
            <v-layout>
              <v-flex md2 class="text-center">
                <v-chip
                  v-on:click="discountBlock = false"
                  class="checkout-back-btn btx-tile-chip cursor-pointer mt-4 normal-text-2 w-100 justify-center"
                >
                  <v-icon color="cyan">mdi-arrow-left-bold-circle-outline</v-icon>
                </v-chip>
              </v-flex>
              <v-flex md5 class="text-center">
                <v-chip
                  :class="{ 'chip-active': discountTypeTab == 'order' }"
                  v-on:click="initDiscount('order')"
                  class="btx-tile-chip cursor-pointer discount-type-checkout-chip mt-4 normal-text-3 w-100 justify-center"
                >
                  ORDER
                </v-chip>
              </v-flex>
              <v-flex md5 class="text-center">
                <v-chip
                  :class="{ 'chip-active': discountTypeTab == 'item' }"
                  v-on:click="initDiscount('item')"
                  class="btx-tile-chip cursor-pointer discount-type-checkout-chip mt-4 normal-text-3 w-100 justify-center"
                >
                  ITEM
                </v-chip>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex md4 class="text-center">
                <v-chip
                  v-on:click="discountTab = 'percentage'"
                  :class="{ 'chip-active': discountTab == 'percentage' }"
                  class="btx-tile-chip text-truncate cursor-pointer discount-type-checkout-chip mt-4 normal-text-2-bold w-100 justify-center"
                >
                  PERCENTAGE
                </v-chip>
              </v-flex>
              <v-flex md4 class="text-center">
                <v-chip
                  v-on:click="discountTab = 'amount'"
                  :class="{ 'chip-active': discountTab == 'amount' }"
                  class="btx-tile-chip text-truncate cursor-pointer discount-type-checkout-chip mt-4 normal-text-2-bold w-100 justify-center"
                >
                  AMOUNT
                </v-chip>
              </v-flex>
              <v-flex md4 class="text-center">
                <v-chip
                  v-on:click="discountTab = 'foc'"
                  :class="{ 'chip-active': discountTab == 'foc' }"
                  class="btx-tile-chip text-truncate cursor-pointer discount-type-checkout-chip mt-4 normal-text-2-bold w-100 justify-center"
                >
                  FOC
                </v-chip>
              </v-flex>
            </v-layout>
            <div v-if="discountTab == 'percentage'" class="checkout-discount-items">
              <v-btn
                depressed
                tile
                class="my-2 w-100 btx-border"
                v-for="count in 100"
                :key="count"
                v-on:click="addDiscount(count)"
                ><template v-if="discountTypeTab == 'item'">ITEM</template>DISC {{ count }}%</v-btn
              >
            </div>
            <div class="pb-5" v-if="discountTab == 'amount'">
              <v-text-field
                depressed
                tile
                :placeholder="`Enter ${
                  discountTypeTab == 'item' ? 'Item' : 'Order'
                } Discount Amount`"
                solo
                @focus="keyboardNumber(discountAmount, 'discount-amount')"
                hide-details
                dense
                v-model="discountAmount"
                ref="discount-amount"
                id="discount-amount"
                color="cyan"
                flat
                prepend-inner-icon="mdi-currency-usd"
                append-outer-icon="mdi-calculator"
                v-on:click:append-outer="keyboardNumber(discountAmount, 'discount-amount')"
                class="w-100 btx-border mt-5 show-number-keyboard pos-item-search"
              ></v-text-field>
              <v-btn
                color="cyan white--text"
                depressed
                tile
                class="w-100 mt-4"
                v-on:click="addDiscount(discountAmount)"
                >APPLY</v-btn
              >
            </div>
            <div v-if="discountTab == 'foc'" class="checkout-discount-items">
              <v-btn
                v-for="(gift, index) in item_gifts"
                :key="`item-gift-${index}`"
                v-on:click="addGift(gift)"
                depressed
                tile
                class="my-2 w-100 btx-border"
                >{{ gift.name }}</v-btn
              >
            </div>
          </template>
          <template v-else-if="couponBlock">
            <v-layout>
              <v-flex md2>
                <v-chip
                  v-on:click="couponBlock = false"
                  class="checkout-back-btn btx-tile-chip cursor-pointer mt-4 normal-text-2 w-100 justify-center"
                >
                  <v-icon color="cyan">mdi-arrow-left-bold-circle-outline</v-icon>
                </v-chip>
              </v-flex>
              <v-flex md10>
                <v-chip
                  class="checkout-back-btn btx-tile-chip discount-type-checkout-chip cursor-pointer mt-4 normal-text-2 w-100 justify-center"
                >
                  COUPON CODE
                </v-chip>
              </v-flex>
            </v-layout>
            <v-layout v-if="false">
              <v-flex md12>
                <v-text-field
                  depressed
                  tile
                  placeholder="Enter Coupon Code"
                  solo
                  @focus="show_keyboard = true"
                  hide-details
                  dense
                  v-model="couponCode"
                  ref="coupon-code"
                  color="cyan"
                  flat
                  prepend-inner-icon="mdi-ticket-percent-outline mdi-rotate-315"
                  append-outer-icon="mdi-keyboard-variant"
                  v-on:click:append-outer="show_keyboard = true"
                  class="w-100 btx-border my-4 show-keyboard pos-item-search"
                ></v-text-field>
                <v-btn color="cyan white--text" depressed tile class="w-100">APPLY</v-btn>
              </v-flex>
            </v-layout>
            <div class="mt-4 btx-customer-voucher-list">
              <div
                class="cursor-pointer btx-coupon-code"
                v-for="(coupon, index) in coupon_code"
                :key="index"
                v-on:click="applyCoupon(coupon)"
                :class="{ 'btx-coupon-code-applied': isCouponApplied(coupon) }"
              >
                <v-img contain :src="coupon.image" :lazy-src="coupon.image" max-height="200px">
                  <v-row align="end" class="fill-height">
                    <v-col m12 class="text-left coupon-text">
                      <v-list-item color="rgba(0, 0, 0, .4)" dark>
                        <v-list-item-content>
                          <v-list-item-title class="text-h5 text-uppercase">{{
                            coupon.vcode
                          }}</v-list-item-title>
                          <v-list-item-title class="text-h6">{{
                            formatDate(coupon.expiry_date)
                          }}</v-list-item-title>
                          <v-list-item-title class="text-h6">{{
                            formatCurrency(coupon.amount)
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-img>
                <v-btn
                  v-if="isCouponApplied(coupon)"
                  class="voucher-delete-btn"
                  v-on:click.stop.prevent="removeCoupon()"
                  icon
                  color="red lighten-1"
                  ><v-icon>mdi-delete-forever</v-icon></v-btn
                >
              </div>
            </div>
          </template>
          <template v-else-if="extraFeeBlock">
            <v-layout>
              <v-flex md2>
                <v-chip
                  v-on:click="extraFeeBlock = false"
                  class="checkout-back-btn btx-tile-chip cursor-pointer mt-4 normal-text-2 w-100 justify-center"
                >
                  <v-icon color="cyan">mdi-arrow-left-bold-circle-outline</v-icon>
                </v-chip>
              </v-flex>
              <v-flex md10>
                <v-chip
                  class="checkout-back-btn btx-tile-chip discount-type-checkout-chip cursor-pointer mt-4 normal-text-2 w-100 justify-center"
                >
                  Extra Fee
                </v-chip>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex md12 class="checkout-extra-fees">
                <v-btn
                  v-for="(extra, index) in extra_fees"
                  :key="index"
                  depressed
                  tile
                  v-on:click="applyExtraFee(extra)"
                  class="my-2 mt-4 w-100 btx-border"
                >
                  {{ extra.name }} [{{ formatCurrency(extra.amount) }}]
                </v-btn>
              </v-flex>
            </v-layout>
          </template>
          <template v-else-if="billSplitBlock">
            <v-layout>
              <v-flex md2>
                <v-chip
                  v-on:click="billSplitBlock = false"
                  class="checkout-back-btn btx-tile-chip cursor-pointer mt-4 normal-text-2 w-100 justify-center"
                >
                  <v-icon color="cyan">mdi-arrow-left-bold-circle-outline</v-icon>
                </v-chip>
              </v-flex>
              <v-flex md10>
                <v-chip
                  class="checkout-back-btn btx-tile-chip discount-type-checkout-chip cursor-pointer mt-4 normal-text-2 w-100 justify-center"
                >
                  SPLIT BILL
                </v-chip>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex md6>
                <v-chip
                  :class="{ 'chip-active': splitBillTab == 'item' }"
                  v-on:click="splitBillTab = 'item'"
                  class="btx-tile-chip cursor-pointer discount-type-checkout-chip mt-4 normal-text-2 w-100 justify-center"
                >
                  ITEM
                </v-chip>
              </v-flex>
              <v-flex md6 v-if="false">
                <v-chip
                  :class="{ 'chip-active': splitBillTab == 'price' }"
                  v-on:click="splitBillTab = 'price'"
                  class="btx-tile-chip cursor-pointer discount-type-checkout-chip mt-4 normal-text-2 w-100 justify-center"
                >
                  AMOUNT
                </v-chip>
              </v-flex>
              <v-flex md6>
                <v-chip
                  :class="{ 'chip-active': splitBillTab == 'person' }"
                  v-on:click="splitBillTab = 'person'"
                  class="btx-tile-chip cursor-pointer discount-type-checkout-chip mt-4 normal-text-2 w-100 justify-center"
                >
                  PERSON
                </v-chip>
              </v-flex>
            </v-layout>
            <v-layout class="pb-5">
              <v-flex m12>
                <template v-if="splitBillTab == 'item'">
                  <p class="small-text-2 my-4 text-capitalize font-light-bold">
                    Select items then click on below button
                  </p>
                </template>
                <template v-if="false && splitBillTab == 'price'">
                  <v-layout>
                    <v-flex md12>
                      <v-text-field
                        v-for="(row, index) in splitAmount"
                        :key="index"
                        depressed
                        tile
                        :id="`split-amount-${index}`"
                        :ref="`split-amount-${index}`"
                        placeholder="Amount"
                        solo
                        @focus="keyboardNumber(row.amount, `split-amount-${index}`)"
                        hide-details
                        dense
                        v-model="row.amount"
                        color="cyan"
                        prepend-inner-icon="mdi-currency-usd"
                        append-outer-icon="mdi-calculator"
                        v-on:click:append-outer="
                          keyboardNumber(row.amount, `split-amount-${index}`)
                        "
                        class="w-100 btx-border my-4 show-number-keyboard pos-item-search"
                        flat
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </template>
                <template v-if="splitBillTab == 'person'">
                  <v-layout class="my-8">
                    <v-flex>
                      <v-btn
                        icon
                        v-on:click="updateSplitPerson('decrease')"
                        color="red lighten-1"
                        class="bg-grey-lighten-4"
                        ><v-icon>mdi-minus</v-icon></v-btn
                      >
                    </v-flex>
                    <v-flex class="mx-2 normal-text-2">x{{ splitAmountPerson }}</v-flex>
                    <v-flex>
                      <v-btn
                        icon
                        v-on:click="updateSplitPerson('increase')"
                        color="green lighten-1"
                        class="bg-grey-lighten-4"
                        ><v-icon>mdi-plus</v-icon></v-btn
                      >
                    </v-flex>
                  </v-layout>
                </template>
                <v-btn color="cyan white--text" depressed tile class="w-100"
                  >SPLIT &amp; SAVE</v-btn
                >
              </v-flex>
            </v-layout>
          </template>
          <template v-else>
            <v-row>
              <v-col class="text-center" md="6">
                <div
                  v-on:click="discountBlock = true"
                  class="cursor-pointer btx-border btx-border-radius-4px py-2"
                >
                  <span class="svg-icon svg-icon-secondary svg-icon-3x svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg :src="$assetURL('media/svg/icons/Shopping/Sale2.svg')" />
                    <!--end::Svg Icon-->
                  </span>
                  <p class="m-0 mt-2">Discount</p>
                </div>
              </v-col>
              <v-col class="text-center" md="6">
                <div
                  v-on:click="getAvailableVouchers()"
                  class="cursor-pointer btx-border btx-border-radius-4px py-2"
                >
                  <span class="svg-icon svg-icon-secondary svg-icon-3x svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg :src="$assetURL('media/svg/icons/Shopping/Ticket.svg')" />
                    <!--end::Svg Icon-->
                  </span>
                  <p class="m-0 mt-2">Coupon</p>
                </div>
              </v-col>
              <v-col v-if="extra_fees.length" class="text-center" md="6">
                <div
                  v-on:click="extraFeeBlock = true"
                  class="cursor-pointer btx-border btx-border-radius-4px py-2"
                >
                  <span class="svg-icon svg-icon-secondary svg-icon-3x svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg :src="$assetURL('media/svg/icons/Shopping/Dollar.svg')" />
                    <!--end::Svg Icon-->
                  </span>
                  <p class="m-0 mt-2">Extra Fee</p>
                </div>
              </v-col>
              <v-col v-if="customer && customer.point_earned" class="text-center" md="6">
                <div
                  v-on:click="applyPoints()"
                  class="cursor-pointer btx-border btx-border-radius-4px py-2"
                >
                  <span class="svg-icon svg-icon-secondary svg-icon-3x svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg :src="$assetURL('media/svg/icons/Shopping/MC.svg')" />
                    <!--end::Svg Icon-->
                  </span>
                  <p class="m-0 mt-2">{{ customer.point_earned }} Points</p>
                </div>
              </v-col>
              <v-col class="text-center" md="6">
                <div
                  v-on:click="billSplitBlock = true"
                  class="cursor-pointer btx-border btx-border-radius-4px py-2"
                >
                  <span class="svg-icon svg-icon-secondary svg-icon-3x svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg :src="$assetURL('media/svg/icons/Shopping/Wallet3.svg')" />
                    <!--end::Svg Icon-->
                  </span>
                  <p class="m-0 mt-2">Split Bill</p>
                </div>
              </v-col>
            </v-row>
          </template>
        </div>
      </v-flex>
      <v-flex md4 class="p-4 grey lighten-3">
        <div class="w-100 bg-white btx-border-radius-4px p-4 h-100 position-relative">
          <div class="text-center pt-2">
            <p class="m-0 normal-text-3-semi-bold deep-orange--text">
              Receivables: {{ formatCurrency(table.total) }}
            </p>
            <p class="m-0 my-1 normal-text-4-semi-bold green--text">
              Paid: {{ formatCurrency(paid_amount) }}
            </p>
            <p class="m-0 normal-text-3-semi-bold deep-orange--text">
              Changes: {{ get_change_amount() }}
            </p>
          </div>
          <div class="btx-payment-block">
            <v-layout class="w-100 my-4">
              <v-flex
                md4
                class="text-center cursor-pointer payment-type-list"
                v-on:click="paymentType = 'cash'"
                :class="{ 'payment-type-active': paymentType == 'cash' }"
              >
                <div class="btx-border btx-border-radius-4px py-2 position-relative">
                  <span class="svg-icon svg-icon-secondary svg-icon-3x svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg :src="$assetURL('media/svg/icons/Shopping/Wallet.svg')" />
                    <!--end::Svg Icon-->
                  </span>
                  <span
                    v-if="paymentType == 'cash'"
                    class="svg-icon payment-type-active-icon svg-icon-3x svg-icon-primary"
                  >
                    <!--begin::Svg Icon-->
                    <inline-svg :src="$assetURL('media/misc/check.svg')" />
                    <!--end::Svg Icon-->
                  </span>
                  <p class="m-0 mt-2 small-text-2">Cash</p>
                </div>
              </v-flex>
              <v-flex
                md4
                class="text-center mx-4 cursor-pointer payment-type-list"
                v-on:click="
                  paymentType = 'card';
                  setDefaultPaymentMethod('card');
                "
                :class="{ 'payment-type-active': paymentType == 'card' }"
              >
                <div class="btx-border btx-border-radius-4px py-2 position-relative">
                  <span class="svg-icon svg-icon-secondary svg-icon-3x svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg :src="$assetURL('media/svg/icons/Shopping/Credit-card.svg')" />
                    <!--end::Svg Icon-->
                  </span>
                  <span
                    v-if="paymentType == 'card'"
                    class="svg-icon payment-type-active-icon svg-icon-3x svg-icon-primary"
                  >
                    <!--begin::Svg Icon-->
                    <inline-svg :src="$assetURL('media/misc/check.svg')" />
                    <!--end::Svg Icon-->
                  </span>
                  <p class="m-0 mt-2 small-text-2">Card</p>
                </div>
              </v-flex>
              <v-flex
                md4
                class="text-center cursor-pointer payment-type-list"
                v-on:click="
                  paymentType = 'other';
                  setDefaultPaymentMethod('other');
                "
                :class="{ 'payment-type-active': paymentType == 'other' }"
              >
                <div class="btx-border btx-border-radius-4px py-2 position-relative">
                  <span class="svg-icon svg-icon-secondary svg-icon-3x svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg :src="$assetURL('media/svg/icons/Design/Substract.svg')" />
                    <!--end::Svg Icon-->
                  </span>
                  <span
                    v-if="paymentType == 'other'"
                    class="svg-icon payment-type-active-icon svg-icon-3x svg-icon-primary"
                  >
                    <!--begin::Svg Icon-->
                    <inline-svg :src="$assetURL('media/misc/check.svg')" />
                    <!--end::Svg Icon-->
                  </span>
                  <p class="m-0 mt-2 small-text-2">Other</p>
                </div>
              </v-flex>
            </v-layout>
            <template v-if="paymentType == 'cash'">
              <v-layout class="btx-border btx-border-radius-4px py-1 align-center w-100">
                <v-flex md2><p class="m-0 normal-text-1 px-3">CASH</p></v-flex>
                <v-flex
                  ><p class="m-0 normal-text-4-semi-bold px-3 text-left">
                    {{ formatCurrency(paid_amount) }}
                  </p></v-flex
                >
                <v-flex md2 class="text-right"
                  ><v-btn
                    v-on:click="paid_amount = null"
                    small
                    color="red lighten-1"
                    class="mx-2"
                    icon
                    ><v-icon>mdi-close</v-icon></v-btn
                  ></v-flex
                >
              </v-layout>
              <v-layout class="mt-4 pos-number-pad btx-border-radius-4px">
                <v-flex md9>
                  <v-layout>
                    <v-flex md4>
                      <v-btn
                        v-on:click="num_pad_key('1')"
                        depressed
                        tile
                        class="btx-border p-3 py-4 btx-border-top-left-radius-4px normal-text-3 w-100 h-100"
                        >1</v-btn
                      >
                    </v-flex>
                    <v-flex md4>
                      <v-btn
                        v-on:click="num_pad_key('2')"
                        depressed
                        tile
                        class="btx-border p-3 py-4 normal-text-3 w-100 h-100"
                        >2</v-btn
                      >
                    </v-flex>
                    <v-flex md4>
                      <v-btn
                        v-on:click="num_pad_key('3')"
                        depressed
                        tile
                        class="btx-border p-3 py-4 normal-text-3 w-100 h-100"
                        >3</v-btn
                      >
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex md4>
                      <v-btn
                        v-on:click="num_pad_key('4')"
                        depressed
                        tile
                        class="btx-border p-3 py-4 normal-text-3 w-100 h-100"
                        >4</v-btn
                      >
                    </v-flex>
                    <v-flex md4>
                      <v-btn
                        v-on:click="num_pad_key('5')"
                        depressed
                        tile
                        class="btx-border p-3 py-4 normal-text-3 w-100 h-100"
                        >5</v-btn
                      >
                    </v-flex>
                    <v-flex md4>
                      <v-btn
                        v-on:click="num_pad_key('6')"
                        depressed
                        tile
                        class="btx-border p-3 py-4 normal-text-3 w-100 h-100"
                        >6</v-btn
                      >
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex md4>
                      <v-btn
                        v-on:click="num_pad_key('7')"
                        depressed
                        tile
                        class="btx-border p-3 py-4 normal-text-3 w-100 h-100"
                        >7</v-btn
                      >
                    </v-flex>
                    <v-flex md4>
                      <v-btn
                        v-on:click="num_pad_key('8')"
                        depressed
                        tile
                        class="btx-border p-3 py-4 normal-text-3 w-100 h-100"
                        >8</v-btn
                      >
                    </v-flex>
                    <v-flex md4>
                      <v-btn
                        v-on:click="num_pad_key('9')"
                        depressed
                        tile
                        class="btx-border p-3 py-4 normal-text-3 w-100 h-100"
                        >9</v-btn
                      >
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex md4>
                      <v-btn
                        v-on:click="num_pad_key('.')"
                        :disabled="haveDotInString()"
                        depressed
                        tile
                        class="btx-border p-3 py-4 normal-text-3 btx-border-bottom-left-radius-4px w-100 h-100"
                        ><v-icon color="black" x-small>mdi-record</v-icon></v-btn
                      >
                    </v-flex>
                    <v-flex md4>
                      <v-btn
                        v-on:click="num_pad_key('0')"
                        depressed
                        tile
                        class="btx-border p-3 py-4 normal-text-3 w-100 h-100"
                        >0</v-btn
                      >
                    </v-flex>
                    <v-flex md4>
                      <v-btn
                        v-on:click="num_pad_key('backspace')"
                        depressed
                        tile
                        class="btx-border p-3 py-4 normal-text-3 w-100 h-100"
                        ><v-icon color="black" small>mdi-backspace-outline</v-icon></v-btn
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex md3>
                  <v-btn
                    v-on:click="submitTable('pay_close')"
                    depressed
                    tile
                    color="green white--text"
                    class="btx-border-bottom-right-radius-4px text-center btx-border-top-right-radius-4px btx-border p-3 py-4 normal-text-3 w-100 h-100 pos-number-pay"
                    >PAY<br />&amp;<br />CLOSE</v-btn
                  >
                </v-flex>
              </v-layout>
            </template>
            <template v-else>
              <v-sheet v-if="paymentType == 'card'">
                <v-container class="py-0 mb-4 btx-border btx-border-radius-4px">
                  <v-row>
                    <v-col
                      class="my-auto payment-methods position-relative"
                      md="3"
                      v-for="(row, index) in payment_methods.card"
                      v-on:click="setPaymentMethod(row)"
                      :key="index"
                    >
                      <v-img class="cursor-pointer" contain :src="row.image"></v-img>
                      <span
                        v-if="payment_method && payment_method.id == row.id"
                        class="svg-icon payment-type-active-icon svg-icon-2x svg-icon-primary"
                      >
                        <!--begin::Svg Icon-->
                        <inline-svg :src="$assetURL('media/misc/check.svg')" />
                        <!--end::Svg Icon-->
                      </span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-sheet>
              <v-sheet v-else-if="paymentType == 'other'">
                <v-container class="py-0 mb-4 btx-border btx-border-radius-4px">
                  <v-row>
                    <v-col
                      class="my-auto payment-methods position-relative"
                      md="3"
                      v-for="(row, index) in payment_methods.other"
                      v-on:click="setPaymentMethod(row)"
                      :key="index"
                    >
                      <v-img class="cursor-pointer" contain :src="row.image"></v-img>
                      <span
                        v-if="payment_method && payment_method.id == row.id"
                        class="svg-icon payment-type-active-icon svg-icon-2x svg-icon-primary"
                      >
                        <!--begin::Svg Icon-->
                        <inline-svg :src="$assetURL('media/misc/check.svg')" />
                        <!--end::Svg Icon-->
                      </span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-sheet>
              <v-text-field
                depressed
                tile
                solo
                @focus="keyboardString(transaction_no, 'transaction-no')"
                @click:clear="
                  transaction_no = null;
                  keyboardString(transaction_no, 'transaction-no');
                "
                hide-details
                dense
                v-model="transaction_no"
                ref="transaction-no"
                id="transaction-no"
                color="cyan"
                flat
                clearable
                placeholder="Transaction #"
                append-outer-icon="mdi-keyboard-variant"
                v-on:click:append-outer="keyboardString(transaction_no, 'transaction-no')"
                class="w-100 btx-border show-keyboard pos-item-search btx-border-radius-4px"
              ></v-text-field>
              <v-btn
                v-on:click="submitTable('save_close')"
                color="green white--text"
                depressed
                tile
                class="mt-4 w-100 btx-border-radius-4px"
                >Save &amp; Close</v-btn
              >
            </template>
          </div>
        </div>
      </v-flex>
    </v-layout>
    <NumberKeyboard
      v-if="show_number_keyboard"
      v-model="value_number_model"
      v-on:close="keyboardClose()"
    ></NumberKeyboard>
    <Keyboard
      v-if="show_keyboard"
      v-model="value_string_model"
      v-on:close="keyboardClose()"
    ></Keyboard>
    <Dialog :dialog="customerDialog" :dialogWidth="dialogWidth">
      <template v-slot:title>
        <v-layout>
          <v-flex>Customer</v-flex>
          <v-flex class="text-right">
            <v-btn
              depressed
              tile
              icon
              v-on:click="customerDialog = false"
              color="red lighten-1 white--text"
              class="h-100"
              ><v-icon> mdi-close </v-icon></v-btn
            >
          </v-flex>
        </v-layout>
      </template>
      <template v-slot:body>
        <v-layout class="mb-4">
          <v-flex md9>
            <v-text-field
              depressed
              tile
              solo
              @focus="keyboardString(searchCustomer, 'search-customer')"
              @click:clear="
                searchCustomer = null;
                keyboardString(searchCustomer, 'search-customer');
              "
              hide-details
              dense
              v-model="searchCustomer"
              ref="search-customer"
              id="search-customer"
              color="cyan"
              flat
              clearable
              placeholder="Search by customer #, name, email or phone no."
              append-outer-icon="mdi-keyboard-variant"
              v-on:click:append-outer="keyboardString(searchCustomer, 'search-customer')"
              class="w-100 btx-border show-keyboard pos-item-search"
            ></v-text-field>
          </v-flex>
          <v-flex md3 class="ml-4">
            <v-btn
              depressed
              tile
              :disabled="customerLoading"
              v-on:click="searchCustomers()"
              color="cyan white--text"
              class="w-100 h-100"
              >Search</v-btn
            >
          </v-flex>
        </v-layout>
        <template v-if="customer_list.length">
          <div class="btx-customer-list btx-border">
            <v-layout
              class="cursor-pointer pos-customer-list py-2"
              v-for="(row, index) in customer_list"
              :class="{ 'btx-border-bottom': customer_list.length > 1 && customer_list[index + 1] }"
              v-on:click="selectCustomer(row)"
              :key="index"
            >
              <v-flex md2 class="text-center my-auto">
                <v-avatar size="70">
                  <v-img
                    contain
                    :src="$assetURL(row.profile)"
                    :lazy-src="$assetURL('media/users/default.jpg')"
                  ></v-img>
                </v-avatar>
              </v-flex>
              <v-flex md8 class="my-auto">
                <v-chip class="cyan cyan--text" outlined label small>{{ row.barcode }}</v-chip>
                <p class="pt-1 m-0 normal-text-2-semi-bold black--text" v-if="row.full_name">
                  {{ row.full_name }}
                </p>
                <p class="m-0 normal-text-2-semi-bold" v-if="row.phone_number">
                  {{ row.phone_number }}
                </p>
                <p class="m-0 normal-text-2-semi-bold" v-if="row.email">{{ row.email }}</p>
              </v-flex>
              <v-flex md2 class="text-center my-auto">
                <p class="m-0">
                  Available Points <v-chip color="cyan white--text">{{ row.point_earned }}</v-chip>
                </p>
              </v-flex>
            </v-layout>
          </div>
        </template>
        <template v-else>
          <v-layout>
            <v-flex>
              <p class="m-0 text-center normal-text-2">
                <img
                  width="30"
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image mr-4"
                />
                Uhh... No customer found at the moment.
              </p>
            </v-flex>
          </v-layout>
        </template>
        <v-btn
          depressed
          tile
          :disabled="customerLoading"
          v-on:click="
            customerDialog = false;
            createCustomerDialog = true;
          "
          color="cyan white--text"
          class="mt-5 w-100"
          >Create new Customer</v-btn
        >
      </template>
    </Dialog>
    <Dialog :dialog="createCustomerDialog" :dialogWidth="dialogWidth">
      <template v-slot:title>
        <v-layout>
          <v-flex>New Customer</v-flex>
          <v-flex class="text-right">
            <v-btn
              depressed
              v-on:click="createCustomer()"
              tile
              color="cyan white--text"
              class="mr-2 h-100"
              >Save</v-btn
            >
            <v-btn
              depressed
              tile
              v-on:click="
                createCustomerDialog = false;
                customerDialog = true;
              "
              class="ml-2 h-100"
              >Cancel</v-btn
            >
          </v-flex>
        </v-layout>
      </template>
      <template v-slot:body>
        <v-layout>
          <v-flex md6 class="mr-2">
            <label for="new-customer-phone" class="btx-label">Phone No.</label>
            <v-layout>
              <v-flex md3>
                <v-select
                  :items="countries"
                  item-text="text"
                  item-value="value"
                  hide-details
                  depressed
                  dense
                  flat
                  tile
                  color="cyan"
                  solo
                  class="btx-border"
                  v-model="newCustomer.dial_code"
                >
                </v-select>
              </v-flex>
              <v-flex md9>
                <v-text-field
                  depressed
                  tile
                  solo
                  @focus="keyboardNumber(newCustomer.phone, 'new-customer-phone')"
                  @click:clear="
                    newCustomer.phone = null;
                    keyboardNumber(newCustomer.phone, 'new-customer-phone');
                  "
                  hide-details
                  dense
                  v-model="newCustomer.phone"
                  ref="new-customer-phone"
                  id="new-customer-phone"
                  color="cyan"
                  flat
                  clearable
                  placeholder="Phone No."
                  append-outer-icon="mdi-calculator"
                  v-on:click:append-outer="keyboardNumber(newCustomer.phone, 'new-customer-phone')"
                  class="w-100 btx-border show-number-keyboard pos-item-search"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex md6 class="ml-2">
            <label for="new-customer-name" class="btx-label">Name</label>
            <v-text-field
              depressed
              tile
              solo
              @focus="keyboardString(newCustomer.name, 'new-customer-name')"
              @click:clear="
                newCustomer.name = null;
                keyboardString(newCustomer.name, 'new-customer-name');
              "
              hide-details
              dense
              v-model="newCustomer.name"
              ref="new-customer-name"
              id="new-customer-name"
              color="cyan"
              flat
              clearable
              placeholder="Name"
              append-outer-icon="mdi-keyboard-variant"
              v-on:click:append-outer="keyboardString(newCustomer.name, 'new-customer-name')"
              class="w-100 btx-border show-keyboard pos-item-search"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout class="mt-4">
          <v-flex md12>
            <label for="new-customer-address" class="btx-label">Address</label>
            <v-text-field
              depressed
              tile
              solo
              @focus="keyboardString(newCustomer.address, 'new-customer-address')"
              @click:clear="
                newCustomer.address = null;
                keyboardString(newCustomer.address, 'new-customer-address');
              "
              hide-details
              dense
              v-model="newCustomer.address"
              ref="new-customer-address"
              id="new-customer-address"
              color="cyan"
              flat
              clearable
              placeholder="Address"
              append-outer-icon="mdi-keyboard-variant"
              v-on:click:append-outer="keyboardString(newCustomer.address, 'new-customer-address')"
              class="w-100 btx-border show-keyboard pos-item-search"
            ></v-text-field>
          </v-flex>
        </v-layout>
      </template>
    </Dialog>
  </div>
</template>
<script>
import POSMixin from "@/core/mixins/pos.mixin";
import { find, isArray, round, sum, toSafeInteger, toNumber, toString, findIndex } from "lodash";
import {
  FINISH_TABLE,
  CREATE_CUSTOMER,
  UPDATE_TABLE_ITEMS,
  GET_TABLE_ITEMS,
  APPLY_COUPON,
  REMOVE_COUPON,
} from "@/core/lib/pos.lib";
import { GET_AVAILABLE_VOUCHERS, GET_CUSTOMERS } from "@/core/lib/customer.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
import Dialog from "@/view/components/Dialog";
import { head } from "lodash";

export default {
  mixins: [POSMixin],
  data() {
    return {
      tableLoading: true,
      formLoading: true,
      customerDialog: false,
      createCustomerDialog: false,
      discountBlock: false,
      finalLoading: false,
      customerLoading: false,
      pointLoading: false,
      couponLoading: false,
      couponBlock: false,
      extraFeeBlock: false,
      billSplitBlock: false,
      show_number_keyboard: false,
      show_keyboard: false,
      discountAmount: null,
      paymentType: "cash",
      splitAmountPerson: 2,
      newCustomer: {
        dial_code: "+60",
        name: null,
        phone: null,
        address: null,
        email: null,
      },
      couponCode: null,
      splitBillTab: "item",
      discountTab: "percentage",
      discountTypeTab: "order",
      discountValue: null,
      gift: null,
      customer_profile: null,
      middle_block: "main",
      child_block: null,
      searchCustomer: null,
      walkInCustomer: true,
      coupon_code: [],
      customer_list: [],
      payment_methods: {
        card: [],
        other: [],
      },
      customer: {},
      splitAmount: [
        {
          amount: null,
        },
        {
          amount: null,
        },
        {
          amount: null,
        },
      ],
      billTab: 1,
      billSplit: true,
      table_loading: true,
      form_loading: false,
      table_items: {
        table_tab_1: [],
        table_tab_2: [],
        table_tab_3: [],
      },
      table_add_ons: [],
      table_extra_fees: [],
      table_order_remark: null,
      tableId: null,
      all_selected: true,
      table: {},
      item_gifts: [],
      extra_fees: [],
      bill_tab: "table_tab_1",
      bill_split: true,
      payment_method: {},
      paid_amount: null,
      transaction_no: null,
      change_amount: null,
      bill_count: {
        1: 0,
        2: 0,
        3: 0,
      },
    };
  },
  watch: {
    discountAmount() {
      if (
        this.$refs["discount-amount"] &&
        this.$refs["discount-amount"].$refs &&
        this.$refs["discount-amount"].$refs.input
      ) {
        this.$nextTick(() => {
          this.$refs["discount-amount"].$refs.input.focus();
        });
      }
    },
    couponCode() {
      if (
        this.$refs["coupon-code"] &&
        this.$refs["coupon-code"].$refs &&
        this.$refs["coupon-code"].$refs.input
      ) {
        this.$nextTick(() => {
          this.$refs["coupon-code"].$refs.input.focus();
        });
      }
    },
  },
  methods: {
    pushToCheckout(param) {
      console.log({ param });
    },
    get_change_amount() {
      let result = null;

      if (toNumber(this.paid_amount) > 0) {
        result = toNumber(this.paid_amount) - toNumber(this.table.total);
      }

      this.change_amount = result;

      return this.formatCurrency(result);
    },
    submitTable(type) {
      if (this.finalLoading) {
        this.$store.commit(SET_ERROR, [{ model: true, message: "Please wait..." }]);
        return false;
      }

      this.finalLoading = true;

      FINISH_TABLE(this.table.id, {
        payment_method: this.payment_method.name,
        paid_amount: this.paid_amount,
        transaction_no: this.transaction_no,
        payment_type: this.paymentType,
        type,
      })
        .then(() => {
          this.$router.push({ name: "pos-table", query: { t: new Date().getTime() } });
        })
        .catch((error) => {
          this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
        })
        .finally(() => {
          this.finalLoading = false;
        });
    },
    haveDotInString() {
      const number = toString(this.paid_amount);
      const split_number = number.split(".");
      if (split_number.length > 1) {
        return true;
      }
      return false;
    },
    num_pad_key(key) {
      const paid_amount_array = toString(this.paid_amount).split(".");
      const paid_amount_first = toString(paid_amount_array[0] ?? "0");
      const paid_amount_second = toString(paid_amount_array[1] ?? "0");

      let paid_amount = paid_amount_first;

      if (paid_amount_array.length > 1) {
        if (key == "backspace") {
          paid_amount = paid_amount_first + "." + paid_amount_second.substring(0, 2);
        } else {
          paid_amount = paid_amount_first + "." + paid_amount_second.substring(0, 1);
        }
      }

      if (key == "backspace") {
        this.paid_amount = toString(paid_amount).slice(0, -1);
      } else {
        this.paid_amount = toString(paid_amount) + toString(key);
      }
    },
    setDefaultPaymentMethod(type) {
      const card = this.payment_methods[type];
      if (card) {
        this.payment_method = head(card);
      }
    },
    setPaymentMethod(row) {
      this.payment_method = row;
    },
    applyPoints() {
      this.$store.commit(SET_ERROR, [{ model: true, message: "Coming Soon..." }]);
      /*if (this.pointLoading) {
        this.$store.commit(SET_ERROR, [{ model: true, message: "Please wait..." }]);
        return false;
      }

      this.pointLoading = true;

      APPLY_POINT(this.table.id)
        .then(data => {
          this.initTable(data);
        })
        .catch(error => {
          this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
        })
        .finally(() => {
          this.pointLoading = false;
        });*/
    },
    createCustomer() {
      if (this.customerLoading) {
        this.$store.commit(SET_ERROR, [{ model: true, message: "Please wait..." }]);
        return false;
      }

      this.customerLoading = true;

      CREATE_CUSTOMER(this.table.id, this.newCustomer)
        .then((data) => {
          this.initTable(data);
        })
        .catch((error) => {
          this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
        })
        .finally(() => {
          this.customerLoading = false;
        });
    },
    selectCustomer(row) {
      if (this.customerLoading) {
        return false;
      }
      this.customer = row;
      this.table.customer = row.id;
      this.walkInCustomer = false;
      this.customerDialog = false;
      this.createCustomerDialog = false;
      this.$nextTick(() => {
        this.updateTable();
      });
    },
    removeCustomer() {
      if (this.customerLoading) {
        return false;
      }
      this.customer = new Object();
      this.table.customer = 0;
      this.walkInCustomer = true;
      this.$nextTick(() => {
        this.updateTable();
      });
    },
    removeTableDiscount() {
      this.discountValue = null;
      this.gift = null;
      this.$nextTick(() => {
        this.updateTable();
      });
    },
    initDiscount(type) {
      this.discountTypeTab = type;
      this.discountAmount = null;
      this.discountTab = "percentage";
      this.discountValue = null;
    },
    isCouponApplied({ vcid }) {
      if (this.table && this.table.coupon_id) {
        return this.table.coupon_id == vcid;
      }
      return false;
    },
    applyCoupon({ vcode, vcid }) {
      if (this.isCouponApplied({ vcid })) {
        return false;
      }

      if (this.couponLoading) {
        this.$store.commit(SET_ERROR, [{ model: true, message: "Please wait..." }]);
        return false;
      }

      this.couponLoading = true;

      APPLY_COUPON(this.table.id, vcode)
        .then((data) => {
          this.initTable(data);
        })
        .catch((error) => {
          this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
        })
        .finally(() => {
          this.couponLoading = false;
        });
    },
    removeCoupon() {
      if (this.couponLoading) {
        this.$store.commit(SET_ERROR, [{ model: true, message: "Please wait..." }]);
        return false;
      }

      this.couponLoading = true;

      REMOVE_COUPON(this.table.id)
        .then((data) => {
          this.initTable(data);
        })
        .catch((error) => {
          this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
        })
        .finally(() => {
          this.couponLoading = false;
        });
    },
    applyExtraFee(extra_fee) {
      const index = findIndex(this.table_extra_fees, { id: extra_fee.id });
      if (index >= 0) {
        this.table_extra_fees.splice(index, 1, extra_fee);
      } else {
        this.table_extra_fees.push(extra_fee);
      }
      this.$nextTick(() => {
        this.updateTable();
      });
    },
    getGiftName(id) {
      const gift = find(this.item_gifts, { id });
      if (gift) {
        return gift.name;
      }
      return "N/A";
    },
    addGift({ id }) {
      if (this.discountTypeTab == "order") {
        this.applyOrderGift(id);
      }
      if (this.discountTypeTab == "item") {
        this.applyItemGift(id);
      }
    },
    applyItemGift(id) {
      const table_tab = this.bill_tab;
      const discount_type = this.discountTab;
      if (discount_type == "foc") {
        let has_change = false;
        if (this.table_items[table_tab] && isArray(this.table_items[table_tab])) {
          for (let i = 0; i < this.table_items[table_tab].length; i++) {
            const { total, status } = this.table_items[table_tab][i];
            if (status) {
              has_change = true;
              this.table_items[table_tab][i].gift = id;
              this.table_items[table_tab][i].discount_type = "foc";
              this.table_items[table_tab][i].discount_value = toNumber(total);
            }
          }
        }

        if (has_change) {
          this.$nextTick(() => {
            this.updateTable();
          });
        }
      }
    },
    applyOrderGift(id) {
      const discount_type = this.discountTab;
      if (discount_type == "foc") {
        this.gift = id;
        this.$nextTick(() => {
          this.updateTable();
        });
      }
    },
    addDiscount(value) {
      const discount_type = this.discountTab;
      const discount_value = value;
      if (this.discountTypeTab == "order") {
        this.applyOrderDiscount(discount_type, discount_value);
      }

      if (this.discountTypeTab == "item") {
        this.applyItemDiscount(discount_type, discount_value);
      }
    },
    applyItemDiscount(discount_type, discount_value) {
      const table_tab = this.bill_tab;
      if (this.table_items[table_tab] && isArray(this.table_items[table_tab])) {
        let has_change = false;
        for (let i = 0; i < this.table_items[table_tab].length; i++) {
          const { total, status } = this.table_items[table_tab][i];
          if (status) {
            has_change = true;
            this.table_items[table_tab][i].discount_type = discount_type;
            this.table_items[table_tab][i].discount_value = toNumber(discount_value);

            if (discount_type == "foc") {
              this.table_items[table_tab][i].discount_value = toNumber(total);
            }
          }
        }

        if (has_change) {
          this.$nextTick(() => {
            this.updateTable();
          });
        }
      }
    },
    applyOrderDiscount(discount_type, discount_value) {
      this.discountValue = discount_value;
      this.gift = null;
      this.$nextTick(() => {
        this.updateTable();
      });
    },
    removeDiscount(index) {
      const table_tab = this.bill_tab;
      if (this.table_items[table_tab] && isArray(this.table_items[table_tab])) {
        this.table_items[table_tab][index].gift = null;
        this.table_items[table_tab][index].discount_type = null;
        this.table_items[table_tab][index].discount_value = null;
        this.$nextTick(() => {
          this.updateTable();
        });
      }
    },
    removeAddOn(index, aindex) {
      const table_tab = this.bill_tab;
      if (this.table_items[table_tab] && isArray(this.table_items[table_tab])) {
        const { quantity, amount } = this.table_items[table_tab][index].add_ons[aindex];
        if (quantity <= 1) {
          this.table_items[table_tab][index].add_ons.splice(aindex, 1);
        } else {
          const newQuantity = toSafeInteger(quantity) - 1;
          this.table_items[table_tab][index].add_ons[aindex].quantity = newQuantity;
          this.table_items[table_tab][index].add_ons[aindex].total = round(amount * newQuantity, 2);
        }
        this.$nextTick(() => {
          this.updateTable();
        });
      }
    },
    removeTableAddOn(index) {
      this.table_add_ons.splice(index, 1);
      this.$nextTick(() => {
        this.updateTable();
      });
    },
    removeTableExtra(index) {
      this.table_extra_fees.splice(index, 1);
      this.$nextTick(() => {
        this.updateTable();
      });
    },
    remarkClear(index) {
      const table_tab = this.bill_tab;
      if (this.table_items[table_tab] && isArray(this.table_items[table_tab])) {
        this.table_items[table_tab][index].remark = null;
        this.$nextTick(() => {
          this.updateTable();
        });
      }
    },
    remarkOrderClear() {
      this.order_remark = null;
      this.table_order_remark = null;
      this.$nextTick(() => {
        this.updateTable();
      });
    },
    selectAllItem() {
      const table_tab = this.bill_tab;
      if (this.table_items[table_tab] && isArray(this.table_items[table_tab])) {
        for (let i = 0; i < this.table_items[table_tab].length; i++) {
          this.table_items[table_tab][i].status = !this.all_selected;
        }
        this.updateSelected();
      }
    },
    async updateSelected() {
      const table_tab = this.bill_tab;
      if (this.table_items[table_tab] && isArray(this.table_items[table_tab])) {
        this.all_selected = true;
        for await (const item of this.table_items[table_tab]) {
          if (!item.status) {
            this.all_selected = false;
          }
        }
      }
    },
    getAlternativeClass(index) {
      if (index % 2 == 0) {
        return "table-item-even";
      }
      return "table-item-odd";
    },
    getItemTotal(index) {
      const totalAmount = [];
      const table_tab = this.bill_tab;
      if (this.table_items[table_tab] && isArray(this.table_items[table_tab])) {
        const { total, add_ons } = this.table_items[table_tab][index];
        totalAmount.push(total);
        if (add_ons && add_ons.length) {
          for (const add_on of add_ons) {
            totalAmount.push(add_on.total);
          }
        }
      }
      return this.formatCurrency(sum(totalAmount));
    },
    totalAmount() {
      return this.formatCurrency(this.table.total);
      /*const total = [];

      const table_tab = this.bill_tab;
      if (this.table_items[table_tab] && isArray(this.table_items[table_tab])) {
        for (let i = 0; i < this.table_items[table_tab].length; i++) {
          total.push(this.table_items[table_tab][i].total);
          const add_ons = this.table_items[table_tab][i].add_ons;
          if (add_ons && add_ons.length) {
            for (const add_on of add_ons) {
              total.push(add_on.total);
            }
          }
        }
      }

      for (let z = 0; z < this.table_add_ons.length; z++) {
        total.push(this.table_add_ons[z].total);
      }

      for (let k = 0; k < this.table_extra_fees.length; k++) {
        total.push(this.table_extra_fees[k].amount);
      }

      return this.formatCurrency(sum(total));*/
    },
    updateSplitPerson(type) {
      const splitAmountPerson = toSafeInteger(this.splitAmountPerson);
      if (type == "decrease" && splitAmountPerson > 2) {
        this.splitAmountPerson = splitAmountPerson - 1;
      }
      if (type == "increase" && splitAmountPerson < 3) {
        this.splitAmountPerson = splitAmountPerson + 1;
      }
    },
    blockChildSwitch(block) {
      switch (block) {
        case "customer":
          this.child_block = this.child_block == "customer" ? null : "customer";
          break;
      }
    },
    updateQty(index, type) {
      const table_tab = this.bill_tab;
      if (this.table_items[table_tab] && isArray(this.table_items[table_tab])) {
        const item = this.table_items[table_tab][index];
        if (item) {
          if (type == "increase") {
            item.quantity = toSafeInteger(item.quantity) + 1;
            item.total = round(item.amount * item.quantity, 2);
            this.table_items[table_tab].splice(index, 1, item);
          } else if (type == "decrease") {
            item.quantity = toSafeInteger(item.quantity) - 1;
            if (item.quantity <= 0) {
              this.table_items[table_tab].splice(index, 1);
            } else {
              item.total = round(item.amount * item.quantity, 2);
              this.table_items[table_tab].splice(index, 1, item);
            }
          }
          this.$nextTick(() => {
            this.updateTable();
          });
        }
      }
    },
    initTable({ table, customer, items, bill_count }) {
      this.table = table;
      this.customer = customer;

      this.discountTypeTab = table.discount_level ? table.discount_level : "order";
      this.discountTab = table.discount_type ? table.discount_type : "percentage";
      this.discountValue = toNumber(table.discount_value);
      this.walkInCustomer = !!+table.walk_in;

      this.gift = table.gift;

      if (table.remark) {
        this.table_order_remark = table.remark;
      }

      this.table_extra_fees = [];

      if (table.extra_fees && table.extra_fees.length) {
        for (let i = 0; i < table.extra_fees.length; i++) {
          this.table_extra_fees.push({
            amount: table.extra_fees[i].amount,
            description: table.extra_fees[i].description,
            id: table.extra_fees[i].extra_fee,
            name: table.extra_fees[i].name,
          });
        }
      }

      this.table_add_ons = [];

      if (table.add_ons && table.add_ons.length) {
        for (let z = 0; z < table.add_ons.length; z++) {
          this.table_add_ons.push({
            amount: table.add_ons[z].amount,
            id: table.add_ons[z].add_on,
            name: table.add_ons[z].name,
            quantity: table.add_ons[z].quantity,
            total: table.add_ons[z].total_amount,
          });
        }
      }

      if (bill_count) {
        this.bill_count = new Object();
        for (let i = 0; i < bill_count.length; i++) {
          this.bill_count[bill_count[i].bill] = bill_count[i].total_bill;
        }
      }

      this.bill_split = !!+table.split;

      this.table_items = new Object({
        table_tab_1: [],
        table_tab_2: [],
        table_tab_3: [],
      });

      if (items.length) {
        for (let z = 0; z < items.length; z++) {
          const table_tab = `table_tab_${items[z].bill}`;
          if (!isArray(this.table_items[table_tab])) {
            this.table_items[table_tab] = [];
          }
          this.table_items[table_tab].push(items[z]);
        }
      }

      this.createCustomerDialog = false;
      this.keyboardClose();
    },
    getTable() {
      GET_TABLE_ITEMS(this.tableId)
        .then((data) => {
          this.initTable(data);
        })
        .catch((error) => {
          this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
          this.goBack();
        })
        .finally(() => {
          this.tableLoading = false;
          this.formLoading = false;
        });
    },
    searchCustomers() {
      this.customerLoading = true;
      GET_CUSTOMERS({ search: this.searchCustomer })
        .then(({ rows }) => {
          this.customer_list = rows;
        })
        .catch((error) => {
          this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
          this.goBack();
        })
        .finally(() => {
          this.customerLoading = false;
        });
    },
    showCustomerDialog() {
      this.customerDialog = true;
      this.customer = new Object();
    },
    updateTable() {
      this.formLoading = true;

      UPDATE_TABLE_ITEMS({
        table: this.table.id,
        customer: this.customer.id,
        remark: this.table_order_remark,
        add_ons: this.table_add_ons,
        extra_fees: this.table_extra_fees,
        discount_level: this.discountTypeTab,
        discount_type: this.discountTab,
        discount_value: this.discountValue,
        walk_in: Number(this.walkInCustomer),
        gift: this.gift,
        item: this.table_items,
      })
        .then((data) => {
          this.initTable(data);
        })
        .catch((error) => {
          this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
    getAvailableVouchers() {
      const { id } = this.customer;

      if (!id) {
        this.$store.commit(SET_ERROR, [
          { model: true, message: "Oops! Please select customer first." },
        ]);
        return false;
      }

      this.coupon_code = [];

      GET_AVAILABLE_VOUCHERS(id)
        .then((data) => {
          if (data.length) {
            this.coupon_code = data;
            this.couponBlock = true;
          } else {
            this.$store.commit(SET_ERROR, [{ model: true, message: "Oops! No Coupon Available." }]);
          }
        })
        .catch((error) => {
          this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
        });
    },
  },
  components: {
    Dialog,
  },
  created() {
    this.tableId = toSafeInteger(this.$route.query.table);
  },
  mounted() {
    this.item_gifts = this.getConfig("item_gifts");
    this.payment_methods = this.getConfig("payment_methods");
    this.extra_fees = this.getConfig("extra_fees");
    if (this.tableId > 0) {
      this.getTable();
    } else {
      this.goBack();
    }
  },
  computed: {
    dialogWidth() {
      return toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
};
</script>
